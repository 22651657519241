import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "satellite-ground-track"
    }}>{`Satellite Ground Track`}</h1>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`Satellite Ground Track enables visualization of the position of Capella's spacecraft projected to the surface of the earth for a given period of time. To request a satellite ground track segment, you will need to know the identifier of the spacecraft and time window that you would like to visualize. The response to a satellite ground track request is a list of epochs and ground coordinates representing the estimated position of the spacecraft for the requested time period.`}</p>
    <h2 {...{
      "id": "getting-spacecraft-ids"
    }}>{`Getting Spacecraft IDs`}</h2>
    <p>{`To request a satellite ground track, you will first need to know the identifier of the spacecraft you would like to visualize. You can find this with a request to the spacecraft endpoint:`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.capellaspace.com/ma/spacecraft/
`}</code></pre>
    <p>{`The response will contain a list of all active spacecraft with the following information:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`spacecraftId`}</inlineCode>{` is the identifier of the spacecraft`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`spacecraftName`}</inlineCode>{` is Capella's given name of the spacecraft`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`noradId`}</inlineCode>{` is the NORAD satellite catalog identifier for the spacecraft`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`generation`}</inlineCode>{` is the name of the generation of the spacecraft`}</li>
    </ul>
    <h2 {...{
      "id": "requesting-a-satellite-ground-track"
    }}>{`Requesting a Satellite Ground Track`}</h2>
    <p>{`Once you have the identifiers of the satellites you would like to visualize, you can make a request to the ground track endpoint:`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.capellaspace.com/ma/groundtrack/{{spacecraftId}}

{
"windowOpen": "2020-08-31T05:30:00.000Z",
"windowClose": "2020-08-31T07:00:00.000Z",
"timestep": 30
}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`windowOpen`}</inlineCode>{` and `}<inlineCode parentName="p">{`windowClose`}</inlineCode>{` parameters indicate the time period for the visualization and `}<inlineCode parentName="p">{`timestep`}</inlineCode>{` is the increment in seconds used to generate the list of epochs and coordinates. The default for timestep is 120 seconds, but to acheive a smoother ground track visualization we recommend using 30 seconds. The maximum duration between `}<inlineCode parentName="p">{`windowOpen`}</inlineCode>{` and `}<inlineCode parentName="p">{`windowClose`}</inlineCode>{` is 1 day and `}<inlineCode parentName="p">{`windowOpen`}</inlineCode>{` must be within 90 days of the current date.`}</p>
    <p>{`The response will contain the list of `}<inlineCode parentName="p">{`epochs`}</inlineCode>{` which are essentially the timestamp of each estimated coordinate in the `}<inlineCode parentName="p">{`state`}</inlineCode>{` list. The coordinates in the `}<inlineCode parentName="p">{`state`}</inlineCode>{` list are in the order: longitude, latitude, altitude.`}</p>
    <h2 {...{
      "id": "additional-information"
    }}>{`Additional Information`}</h2>
    <p>{`For more information on constellation tasking requests, see the API reference for `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/api/tasking"
      }}>{`Tasking`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      